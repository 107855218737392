import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import isAbsoluteURL from "is-absolute-url"
import * as styles from "./ui.css.ts"

export const cx = (...args) => args.filter(Boolean).join(" ")

export function Base({
  as: Component = "div",
  cx: _cx = [],
  className,
  ...props
}) {
  return <Component className={cx(..._cx, className)} {...props} />
}

export function Container({ width = "normal", ...props }) {
  return <Base cx={[styles.containers[width]]} {...props} />
}

export function Flex({
  variant,
  gap = 3,
  gutter,
  wrap,
  responsive,
  marginY,
  alignItems,
  cx: _cx,
  ...props
}) {
  return (
    <Base
      cx={[
        styles.flex,
        styles.flexVariants[variant],
        responsive && styles.flexVariants.responsive,
        wrap && styles.flexVariants.wrap,
        gutter && styles.gutter[gutter],
        gutter ? styles.flexGap[0] : styles.flexGap[gap],
        marginY && styles.marginY[marginY],
        alignItems && styles.flexVariants[alignItems],
        _cx,
      ]}
      {...props}
    />
  )
}

export function Box({
  width = "full",
  background,
  padding,
  paddingY,
  radius,
  center = false,
  order,
  cx,
  ...props
}) {
  return (
    <Base
      cx={[
        styles.widths[width],
        styles.backgrounds[background],
        styles.padding[padding],
        styles.paddingY[paddingY],
        styles.radii[radius],
        center && styles.box.center,
        order && styles.order[order],
        cx,
      ]}
      {...props}
    />
  )
}


export function FlexList({ ...props }) {
  return <Flex as="ul" cx={styles.list} {...props} />
}

export function List(props) {
  return <Base as="ul" cx={[styles.list]} {...props} />
}

export function Space({ size = "auto", ...props }) {
  return <Base cx={[styles.margin[size]]} {...props} />
}

export function Nudge({ left, right, top, bottom, ...props }) {
  return (
    <Base
      cx={[
        styles.margin.left[-left],
        styles.margin.right[-right],
        styles.margin.top[-top],
        styles.margin.bottom[-bottom],
      ]}
      {...props}
    />
  )
}


export function Section(props) {
  return <Box as="section" className={styles.section} {...props} />
}

export function CardSection(props) {
  return <Box as="cardSection" className={styles.cardSection} {...props} />
}


export function Text({ variant = "body", center, bold, ...props }) {
  return (
    <Base
      cx={[
        styles.text[variant],
        center && styles.text.center,
        bold && styles.text.bold,
      ]}
      {...props}
    />
  )
}

export function TextInput({ variant = "body", center, bold, ...props }) {
  console.log('Center = ', center, bold);
  return (
    <Base
      cx={[
        styles.text[variant],
        center && styles.text.center,
        bold && styles.text.bold,
      ]}
      {...props}
    />
  )
}

export function SuperHeading({ ...props }) {
  return <Text as="h1" variant="superHeading" {...props} />
}

export function Heading({ ...props }) {
  return <Text as="h2" variant="heading" {...props} />
}

export function Subhead({ ...props }) {
  return <Text as="h3" variant="subhead" {...props} />
}
export function Head({ ...props }) {
  return <Text as="h3" variant="Head" {...props} />
}

export function SubheadSmall({ ...props }) {
  return <Text as="h4" variant="subheadSmall" {...props} />
}

export function MediumBoldText({ ...props }) {
  return <Text as="h4" variant="mediumBoldText" {...props} />
}

export function Disclaimer({ ...props }) {
  return <Text as="h3" variant="disclaimer" {...props} />
}

export function Input({...props}) {
  return <TextInput variant="formInput" {...props} />
}
export function Megacard({...props}){
  return <Text variant="megacard" {...props}/>
}

export function Card({ ...props }) {
  return <Text variant="card" {...props} />
}
export function ModuleCard({ ...props }) {
  return <Text variant="moduleCard" {...props} />
}
export function ModuleCardFooter({...props}) {
  return <Text variant="moduleCardFooter" {...props} />
}
export function ContactCard({ ...props }) {
  return <Text variant="contactCard" {...props} />
}
export function ContactCardHeader({ ...props }) {
  return <Text variant="contactCardHeader" {...props} />
}
export function ContactCardBody({ ...props }) {
  return <Text variant="contactCardBody" {...props} />
}
export function CardHeader({ ...props }) {
  return <Text variant="cardHeader" {...props} />
}
export function CardBody({ ...props }) {
  return <Text variant="cardBody" {...props} />
}
export function Header({...props}){
  return <Text variant="header"{...props}/>
}
export function FeatureCard({ ...props }) {
  return <Text variant="featureCard" {...props} />
}

export function Kicker({ ...props }) {
  return <Text variant="kicker" {...props} />
}

export function Link({ to, href, ...props }) {
  const url = href || to || ""
  if (isAbsoluteURL(url)) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a href={url} className={styles.link} {...props} />
    )
  }
  return <GatsbyLink to={url} className={styles.link} {...props} />
}

export function NavLink({ ...props }) {
  return <Base as={Link} cx={[styles.navlink]} {...props} />
}

export function NavButtonLink({ ...props }) {
  return <Base as="button" cx={[styles.navButtonlink]} {...props} />
}

export function Button({ variant = "primary", ...props }) {
  return <Base as={Link} cx={[styles.buttons[variant]]} {...props} />
}

export function ButtonList({ links = [], reversed = false, ...props }) {
  const getVariant = (i) => {
    if (reversed) {
      return i === 0 ? "reversed" : "linkReversed"
    }
    return i === 0 ? "primary" : "link"
  }
  return (
    <FlexList marginY={4} {...props}>
      {links &&
        links.map((link, i) => (
          <li key={link.id}>
            <Button href={link.href} variant={getVariant(i)}>
              {link.text}
            </Button>
          </li>
        ))}
    </FlexList>
  )
}

export function CTALink(props) {
  return <Base as={Link} cx={[styles.ctaLink]} {...props} />
}

export function LinkList({ links = [], ...props }) {
  return (
    <FlexList {...props}>
      {links &&
        links.map((link, i) => (
          <li key={link.id}>
            <CTALink href={link.href}>{link.text}</CTALink>
          </li>
        ))}
    </FlexList>
  )
}

export function Blockquote(props) {
  return <Base as="blockquote" cx={[styles.blockquote]} {...props} />
}

export function Avatar({ alt, image }) {
  return (
    <GatsbyImage alt={alt} image={getImage(image)} className={styles.avatar} />
  )
}

export function Logo({ alt, image, size = "small" }) {
  return (
    <GatsbyImage
      alt={alt}
      image={getImage(image)}
      className={styles.logos[size]}
    />
  )
}

export function Icon({ alt, image, size = "medium" }) {
  return (
    <GatsbyImage
      alt={alt}
      image={getImage(image)}
      className={styles.icons[size]}
    />
  )
}

export function IconLink(props) {
  return <NavLink cx={[styles.iconLink]} {...props} />
}

export function InteractiveIcon(props) {
  return <Base as="button" cx={[styles.interactiveIcon]} {...props} />
}

export function VisuallyHidden(props) {
  return <Base as="span" cx={[styles.visuallyHidden]} {...props} />
}

export function PlatformHeading({ ...props }) {
  return <Text as="h3" variant="platformHeading" {...props} />
}

export function PlatformSubhead({ ...props }) {
  return <Text as="h5" variant="platformSubhead" {...props} />
}

export function PlatformPlainText({ ...props }) {
  return <Text as="h5" variant="plainText" {...props} />
}
