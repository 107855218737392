import * as React from "react"
import { Menu, X } from "react-feather"
import {
  Container,
  Flex,
  FlexList,
  Space,
  NavLink,
  Box,
  NavItemGroup,
  InteractiveIcon,
  Nudge,
  VisuallyHidden,
  Button,
} from "./ui"
import {
  mobileNavOverlay,
  activeLink,
  desktopHeaderNavWrapper,
  mobileHeaderNavWrapper,
  mobileNavSVGColorWrapper,
  bg_header,
} from "./header.css.ts"
import { StaticImage } from "gatsby-plugin-image"
import { AiOutlineUser } from "react-icons/ai";
import { logout, isLoggedIn, getCurrentUser } from "./Auth"

import { navigate } from "gatsby"

export default function Header(props) {

  const [isOpen, setOpen] = React.useState(false)

  function getHref() {
    if (isLoggedIn()) {
      return '/Logout';
    } else {
      return '/Login';
    }
  }

  function getText() {
    if (isLoggedIn()) {
      return 'Logout';
    } else {
      return 'Login';
    }
  }

  const navItems = [{
    id: 1,
    href: "/",
    text: "Home",
    description: "Home",
    navItemType: '',
    isActive: props.navName === 'Home' ? true : false
  },
  {
    id: 2,
    href: "/about",
    text: "About",
    description: "About",
    navItemType: '',
    isActive: props.navName === 'About' ? true : false
  },
  {
    id: 3,
    href: "/platform",
    text: "Platform",
    description: "Platform",
    navItemType: '',
    isActive: props.navName === 'Platform' ? true : false
  },
  {
    id: 4,
    href: "/associates",
    text: "Associates",
    description: "Associates",
    navItemType: "",
    isActive: props.navName === 'Associates' ? true : false
  },
  {
    id: 5,
    href: "/mentor",
    text: "Mentor",
    description: "Mentor",
    navItemType: '',
    isActive: props.navName === 'Mentor' ? true : false
  },
  {
    id: 6,
    href: "/contact_us",
    text: "Contact",
    description: "Contact",
    navItemType: '',
    isActive: props.navName === 'Contact' ? true : false
  },
  // {
  //   id: 7,
  //   href: getHref(),
  //   text: getText(),
  //   description: getText(),
  //   navItemType: 'btn',
  //   isActive: props.navName === 'Login' ? true : false
  // },
  // {
  //   text: 'LoginGroup',
  //   name: 'Logout',
  //   subNavItems: [
  //     {
  //       id: '7',
  //       href: "/contact_us",
  //       text: "MyBiz Lab",
  //       description: "MyBizLab",
  //     }, {
  //       id: '8',
  //       href: "/contact_us",
  //       text: "Logout",
  //       description: "Logout",
  //     }
  //   ]
  // }
  ]

  const handlelogout = () => {
    logout()

  }

  const navigateToBizLab = () => {
    let currentUrl = window.location.href;

    if (currentUrl !== undefined && (currentUrl.includes('staging') || currentUrl.includes('localhost'))) {
      navigate(`https://staging.portal.bizzlab.co.in/login_token?t=${getCurrentUser().token}`)
    } else {
      navigate(`https://portal.bizzlab.co.in/login_token?t=${getCurrentUser().token}`)
    }
  }

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "visible"
    }
  }, [isOpen])

  return (
    <header >
      <div className={bg_header}>
        <Container width="container" className={desktopHeaderNavWrapper}>

          <Container width="narrow2">
            <Space size={2} />
            <Flex variant="spaceBetween">
              <NavLink to="/">
                <VisuallyHidden>Home</VisuallyHidden>
                <StaticImage
                  src="../images/bizzlab_logo.png"
                  width="180"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="A Gatsby astronaut"
                  style={{ marginBottom: `1.45rem` }}
                />
              </NavLink>
              <nav>
                <FlexList gap={4}>
                  {navItems &&
                    navItems.map((navItem) => (
                      <li key={navItem.id}>
                        {/* {navItem.text === 'Group' &&
                      <NavItemGroup
                      name={navItem.name}
                      navItems={navItem.subNavItems}
                    />
                        <ul>
                          {navItem.subNavItems.map(subNav => (
                            <li>
                              <NavLink to={subNav.href} > <h4>{subNav.text}</h4> </NavLink>
                            </li>
                          ))}
                        </ul>
                     } */}
                        {navItem.text === 'LoginGroup' && !isLoggedIn() && <Button to="/Login"><AiOutlineUser /> &nbsp;Login</Button>}
                        {navItem.text === 'LoginGroup' && isLoggedIn() && <>
                          <Button onClick={navigateToBizLab}>MyBizLab</Button> {`  `}
                          <Button onClick={handlelogout}>Logout</Button>
                        </>}
                        {navItem.text !== 'Login' && navItem.text !== 'Logout' && navItem.text !== 'LoginGroup' && <NavLink to={navItem.href} >  <h4 className={navItem.isActive ? activeLink : ''}>{navItem.text}</h4>  </NavLink>}
                      </li>
                    ))}
                </FlexList>
              </nav>
            </Flex>
          </Container>
        </Container>
        <Container className={mobileHeaderNavWrapper[isOpen ? "open" : "closed"]}>
          <Space size={2} />
          <Flex variant="spaceBetween">
            <span
              className={
                mobileNavSVGColorWrapper[isOpen ? "reversed" : "primary"]
              }
            >
              <NavLink to="/">
                <VisuallyHidden>Home</VisuallyHidden>
                <StaticImage
                  src="../images/bizzlab_logo.png"
                  width={150}
                  height={35}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="A Gatsby astronaut"
                />
              </NavLink>
            </span>
            <Flex>
              <Space />
              <Nudge right={3}>
                <InteractiveIcon
                  title="Toggle menu"
                  onClick={() => setOpen(!isOpen)}
                  className={
                    mobileNavSVGColorWrapper[isOpen ? "reversed" : "primary"]
                  }
                >
                  {isOpen ? <X /> : <Menu />}
                </InteractiveIcon>
              </Nudge>
            </Flex>
          </Flex>
        </Container>
        {isOpen && (
          <div className={mobileNavOverlay}>
            <nav>
              <FlexList responsive variant="spaceBetween">
                {navItems?.map((navItem) => (
                  navItem.text != "LoginGroup" ?
                    <li>
                      <NavLink to={navItem.href} > {navItem.isActive ? <span className={activeLink}> {navItem.text} </span> : navItem.text} </NavLink>
                    </li> :
                    isLoggedIn() ? <>
                      <li>
                        <NavLink to="#" > <span onClick={navigateToBizLab}> My BizzLab </span> </NavLink>
                      </li>
                      <li>
                        <NavLink to="#" > <span onClick={handlelogout}> Logout </span> </NavLink>
                      </li>
                    </> :
                      <>
                        <li>
                          <NavLink to="/Login"> <span className={activeLink} > Login </span> </NavLink>
                        </li>
                      </>
                ))}
              </FlexList>
            </nav>
          </div>

        )}
      </div>
    </header>
  )
}
