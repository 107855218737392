const isBrowser = typeof window !== `undefined`
export const getUser = () =>
    window.localStorage.skillElevatorUser
        ? JSON.parse(window.localStorage.skillElevatorUser)
        : {}

export const is_admin = () =>
    isBrowser && getUser() && JSON.parse(window.localStorage.skillElevatorUser).is_admin
        ? true
        : false

export const is_mentor = () =>
    isBrowser && getUser() && JSON.parse(window.localStorage.skillElevatorUser).is_mentor
        ? true
        : false

export const setUser = user => (window.localStorage.skillElevatorUser = user == null ? null : JSON.stringify(user))

export const isLoggedIn = () => {
    if (!isBrowser) return false
    const user = getUser()
    var returnValue = false
    if (user && user.token)
        returnValue = true
    else
        returnValue = false
    return returnValue
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = () => {
    if (!isBrowser) return
    window.localStorage.skillElevatorUser = null;

}
