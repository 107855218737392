import React, { useState } from "react";
import Usercontext from "./usercontext";

const UserProvider = (props) => {
    const [user, setUser] = useState({})
    const [loadingMask, setLoadingMask] = useState(false)
    return (
        <Usercontext.Provider value={
            {
                user, setUser,
                loadingMask, setLoadingMask,
            }
        }>
            {props.children}
        </Usercontext.Provider>
    )
}

export default UserProvider
