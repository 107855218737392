import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  Twitter,
  Twitch,
  Instagram,
  Facebook,
  Youtube,
  GitHub,
  Linkedin
} from "react-feather"
import {
  Container,
  Flex,
  FlexList,
  Box,
  Space,
  NavLink,
  Text,
  IconLink,
  VisuallyHidden,
  Section,
  Heading,
  Subhead,
  SubheadSmall,
  Disclaimer,
} from "./ui"
import BrandLogo from "./brand-logo"
import { mobileFooterWrapper, desktopFooterWrapper } from "./header.css"

const socialMedia = {
  TWITTER: {
    url: "https://twitter.com/skillelevator",
    name: "Twitter",
    icon: <Twitter />,
  },
  INSTAGRAM: {
    url: "https://instagram.com",
    name: "Instagram",
    icon: <Instagram />,
  },
  FACEBOOK: {
    url: "https://www.facebook.com/yourskillelevator",
    name: "Facebook",
    icon: <Facebook />,
  },
  YOUTUBE: {
    url: "https://youtube.com",
    name: "YouTube",
    icon: <Youtube />,
  },
  GITHUB: {
    url: "https://github.com",
    name: "GitHub",
    icon: <GitHub />,
  },
  LINKEDIN: {
    url: "https://www.linkedin.com/company/skillelevator/",
    name: "Linkedin",
    icon: <Linkedin />,
  },
  TWITCH: {
    url: "https://twitch.tv",
    name: "Twitch",
    icon: <Twitch />,
  },
}

const getSocialURL = ({ service, username }) => {
  const domain = socialMedia[service]?.url
  if (!domain) return false
  return `${domain}`
  //return `${domain}/${username}`
}

const getSocialIcon = ({ service }) => {
  return socialMedia[service]?.icon
}

const getSocialName = ({ service }) => {
  return socialMedia[service]?.name
}

export default function Footer(props) {

  const socialLinks = [
    { service: "FACEBOOK" },
    { service: "INSTAGRAM" },
    { service: "YOUTUBE" },
    { service: "TWITTER" },
    { service: "LINKEDIN" }]

  return (
    <Section style={{ background: '#0D0D2B', color: '#FFFF' }}>
      <Container>
        <Flex gap={4} variant="responsive">
          <Box>
          <StaticImage src='../images/bizzlab_logo.png' alt="Skill_elevator_icon" width={200} />
           
        
            <Text> <span style={{ color: '#00D4FF', marginLeft: '64px' }}>A Product of</span>
              <br /> Skill-Elevator Technologies Pvt. Ltd. </Text>
          </Box>
          <Box style={{ minWidth: '200px' }}>
            <SubheadSmall> Quick Link </SubheadSmall>
            <Text> <NavLink to="/">Home</NavLink> </Text>
            <Text> <NavLink to="/about">About</NavLink> </Text>
            <Text> <NavLink to="/platform">Platform</NavLink> </Text>
            <Text> <NavLink to="/">Why BizzLab</NavLink> </Text>
            <Text> <NavLink to="/contact">Contact</NavLink>  </Text>
          </Box>
          <Box>
            <SubheadSmall> Contact</SubheadSmall>
            <Text> M: +91 7019124511 </Text>
            <Text>
              <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@skill-elevator.com" target="_blank">E: info@BizzLab.com</a>
            </Text>

            <Text>{`L: No.44, South End Road, Above Axis Bank, Basavanagudi, Bengaluru, Karnataka, 560004, 
               Bengaluru, Karnataka 560004 `} </Text>
          </Box>
          <Box>
            <SubheadSmall>
              We accept following payment systems
            </SubheadSmall>
            <Flex gap={4} variant="responsive" style={{ marginBottom: '20px' }} >
              <FlexList>
                <li key='visa'>
                  <StaticImage
                    src="../images/Visa.png"
                    width={75}
                  />
                </li>
                <li key='mastercard'>
                  <StaticImage
                    src="../images/Mastercard.png"
                    width={75}
                  />
                </li>
                <li key='bitcoin'>
                  <StaticImage
                    src="../images/Bitcoin.png"
                    width={75}
                  />
                </li>
              </FlexList>
            </Flex>

          </Box>
        </Flex>
        <Container width="container" className={desktopFooterWrapper}>
          <Flex gap={4} variant="responsive">
            <Box>
              {/* <Disclaimer>
                ©2022 Skill Elevator Technologies Private Limited. All rights reserved
              </Disclaimer> */}
            </Box>
            <Box >
              <Flex gap={4} variant="responsive" >
                <FlexList>
                  {socialLinks &&
                    socialLinks.map((link) => {
                      const url = getSocialURL(link)
                      return (
                        url && (
                          <li key={link.id}>
                            <IconLink to={url}  target="blank">
                              <VisuallyHidden>{getSocialName(link)}</VisuallyHidden>
                              {getSocialIcon(link)}
                            </IconLink>
                          </li>
                        )
                      )
                    })}
                  <li>
                    <p> ©2021 SKILL-ELEVATOR. All rights reserved </p>
                  </li>
                </FlexList>
              </Flex>
            </Box>
          </Flex>
        </Container>
        <Container className={mobileFooterWrapper["closed"]}>
          <Flex gap={2} variant="responsive">
            <Box>
              {/* <Disclaimer>
                ©2022 Skill Elevator Technologies Private Limited. All rights reserved
              </Disclaimer> */}
            </Box>
            <Box >
              <Flex gap={4} variant="responsive" >
                <FlexList>
                  {socialLinks &&
                    socialLinks.map((link) => {
                      const url = getSocialURL(link)
                      return (
                        url && (
                          <li key={link.id}>
                            <IconLink to={url} target="blank">
                              <VisuallyHidden>{getSocialName(link)}</VisuallyHidden>
                              {getSocialIcon(link)}
                            </IconLink>
                          </li>
                        )
                      )
                    })}
                  <li>
                    <p> ©2021 SKILL-ELEVATOR. All rights reserved </p>
                  </li>
                </FlexList>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Container>
    </Section>
  )
}
